.k-widget {
    font-family: Lato, "Roboto", "Arial", "Helvetica Neue", sans-serif;
}
.k-grid a {
    color: #168abf;
}
.k-grid-dark a {
    color: #044687 !important;
}
.k-grid .k-alt,
.k-grid-header .k-header > .k-link {
    color: #000;
}

.k-grid-header .wrap-header {
    height: auto;
    overflow: visible;
    white-space: normal;
}

.k-custom-grid .k-filter-row td,
.k-filter-row th {
    border-width: 0 0 1px 1px;
    white-space: nowrap;
    padding: 0.3rem 0.3rem;
}

.k-custom-grid tbody tr td {
    text-align: left;
    vertical-align: top;
}

.k-custom-grid .k-grid th,
.k-grid td {
    padding: 0.75rem 0.3rem;
}

.k-notification {
    opacity: 0.9 !important;
    color: #fff !important;
    border: none;
    border-radius: 5px;
    position: inherit;
}

.k-notification-group {
    z-index: 20000;
}
.k-notification-wrap {
    padding: 10px;
}
.k-notification-content {
    font-weight: bold;
    margin: 0 10px 0 5px;
}

.k-notification:hover {
    opacity: 1 !important;
}
.k-notification-info {
    background-color: #337ab7 !important;
}
.k-notification-success {
    background-color: #51a351 !important;
}
.k-notification-warning {
    background-color: #f89406 !important;
}
.k-notification-error {
    background-color: #bd362f !important;
}

td.custom-kendo-cell-grey-border {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
}

td.custom-kendo-cell-border-bottom {
    border-bottom: 2px solid #000;
}

.k-upload-files {
    overflow: auto !important;
    max-height: 380px !important;
}
