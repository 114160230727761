$itm-blue: #2185d0 !important;
$itm-blue-2: #023c5b !important;

.itm-blue {
    color: $itm-blue;
}

.input-blue {
    &:focus-within label {
        color: $itm-blue !important;
    }
    input:active,
    input:focus,
    textarea:active,
    textarea:focus,
    .dropdown:active,
    .dropdown:focus {
        outline: 2px solid $itm-blue !important;
    }
}

.bulk-le-key {
    height: 40px;
    width: 400px;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #666;
}

.k-widget .ui.grid {
    // semantic grids won't take 100% width without when inside kendo components
    flex-grow: 1;
}

.text-center {
    text-align: center !important;
}
.text-danger {
    color: #a94442;
}
.text-green {
    color: #090;
}
.text-muted {
    color: #9497a0 !important;
}
.text-bold {
    font-weight: bold !important;
}

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25
        ),
        2: (
            $spacer * 0.5
        ),
        3: $spacer,
        4: (
            $spacer * 1.5
        ),
        5: (
            $spacer * 3
        )
    ),
    $spacers
);
@each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $length in $spacers {
        .#{$abbrev}-#{$size} {
            #{$prop}: $length !important;
        }
        .#{$abbrev}t-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-top: $length !important;
        }
        .#{$abbrev}r-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-right: $length !important;
        }
        .#{$abbrev}b-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-left: $length !important;
        }
    }
}

@media only screen and (max-width: 1415px) {
    [class*="widescreen only"] {
        display: none !important;
    }
}

// Override react-toastify colors
.Toastify__toast--success {
    background-color: $itm-blue;
}

.Toastify__toast--error {
    background-color: #a94442 !important;
    // background-color: $itm-blue-2;
}
